import React from 'react';
import { createRoot } from 'react-dom/client';
import type { Root } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import { LanguageSync } from '~core/client/language/ui/components/sync';
import type { Room } from '~core/client/room';
import { RoomContext } from '~core/client/room/ui/context';

import type { InterfaceInjector } from './types';

import './resources';
import './styles';

export class Interface {
  private static root?: Root;

  public static inject(containerId: string, Component: React.ReactNode): InterfaceInjector {
    const game = document.getElementById('game-system-ui');
    if (!game) {
      throw Error('System UI container isn`t found');
    }

    const container = document.createElement('div');
    container.id = containerId;
    game.prepend(container);

    const root = createRoot(container);
    // @ts-ignore
    root.render(Component);

    return {
      remove: () => {
        root.unmount();
        container.remove();
      },
    };
  }

  public static mount(Component: React.FC, room: Room<any>) {
    if (!this.root) {
      const container = document.getElementById('game-ui') as HTMLDivElement;
      this.root = createRoot(container);
    }

    this.root.render(
      <RecoilRoot>
        <LanguageSync />
        <RoomContext.Provider value={room}>
          <Component />
        </RoomContext.Provider>
      </RecoilRoot>,
    );
  }

  public static unmount() {
    this.root?.unmount();
    delete this.root;
  }
}

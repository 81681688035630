export type SystemMessageConfig = {
  type: SystemMessageType;
  title: string;
  sign: string;
  message?: string | string[];
  skippable?: boolean;
};

export enum SystemMessageType {
  Info = 'Info',
  Error = 'Error',
}

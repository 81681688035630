import DE from './dictionaries/de';
import EN from './dictionaries/en';
import ES from './dictionaries/es';
import FR from './dictionaries/fr';
import RU from './dictionaries/ru';
import { LanguageType } from './types';

export const DICTIONARIES = {
  [LanguageType.EN]: EN,
  [LanguageType.FR]: FR,
  [LanguageType.ES]: ES,
  [LanguageType.DE]: DE,
  [LanguageType.RU]: RU,
};

export const DICTIONARY_NAME: Record<LanguageType, string> = {
  [LanguageType.EN]: 'English',
  [LanguageType.RU]: 'Русский',
  [LanguageType.ES]: 'Español',
  [LanguageType.DE]: 'Deutsch',
  [LanguageType.FR]: 'Français',
};

/* eslint-disable import/no-restricted-paths */

import { SettingsType } from '~core/client/settings/types';

import { ParameterType } from '~feature/client/battle/entity/unit/player/ui/components/info-panel/parameter/types';
import { KeyAction } from '~feature/client/battle/tutorial/ui/components/hints/content/key/types';
import { SwipeDirection } from '~feature/client/battle/tutorial/ui/components/hints/content/swipe/types';
import { PageType } from '~feature/client/relay/ui/components/pages/types';
import { BuildingCategory, BuildingVariant } from '~feature/shared/battle/entity/building/types';
import { DroidVariant } from '~feature/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';
import { UpgradeCategory, UpgradeVariant } from '~feature/shared/battle/entity/unit/player/upgrades/types';
import { TutorialStep } from '~feature/shared/battle/tutorial/types';
import { BattleDifficult } from '~feature/shared/battle/types';
import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';
import { UserError } from '~feature/shared/mixed/user/types';

export default {
  [PageType.Play]: 'Jugar',
  [PageType.Inventory]: 'Inventario',
  [PageType.Leaderboard]: 'Líderes',
  [PageType.Profile]: 'Perfil',

  LeaveAccount: 'Cerrar sesión',
  ToLogIn: 'Iniciar sesión',
  ToSignUp: 'Registrarse',
  LogIn: 'Iniciar sesión',
  SignUp: 'Registrarse',
  Username: 'Nombre de usuario',
  Password: 'Contraseña',
  AcceptTerms: 'Acepto los términos de',
  PrivacyPolicy: 'la política de privacidad',

  [UserError.ExistUsername]: 'El nombre de usuario especificado ya está en uso',
  [UserError.InvalidToken]: 'Token de autorización inválido',
  [UserError.InvalidCredentials]: 'Nombre de usuario o contraseña incorrectos',
  [UserError.InvalidUsername]: 'Nombre de usuario inválido',
  [UserError.InvalidPassword]: 'Contraseña inválida',

  MainMenu: 'Menú principal',
  Continue: 'Continuar',
  Restart: 'Reiniciar',
  Settings: 'Configuración',
  RestartConfirm: '¿Estás seguro de que quieres empezar de nuevo?',
  LeaveConfirm: '¿Estás seguro de que quieres abandonar esta batalla?',
  Back: 'Atrás',

  Place: 'Lugar',
  MaxWave: 'Máxima ola',
  TotalWaves: 'Total de olas',

  AuthTo: 'Iniciar sesión en',
  SaveReward: 'Guardar recompensa',
  LoadSave: 'Cargar partida',
  PlayAgain: 'Jugar de nuevo',
  WaitingOpponent: 'Esperando al oponente',
  OpponentDisconnected: 'El oponente abandonó la batalla',

  YouWin: 'Ganaste',
  YouLose: 'Perdiste',
  YouDied: 'Moriste',
  BaseDestroyed: 'Base destruida',

  [`Difficult${BattleDifficult.Tutorial}`]: 'Tutorial',
  [`Difficult${BattleDifficult.Tutorial}Description`]: 'Para la primera partida',
  [`Difficult${BattleDifficult.Normal}`]: 'Normal',
  [`Difficult${BattleDifficult.Normal}Description`]: 'Para principiantes',
  [`Difficult${BattleDifficult.Hard}`]: 'Difícil',
  [`Difficult${BattleDifficult.Hard}Description`]: 'Para jugadores experimentados',

  PlayerOnline: 'Jugador en línea',
  PlayersOnline: 'Jugadores en línea',

  YouRanked: 'Estás clasificado',
  PlaceOf: 'lugar de',

  SearchingOpponent: 'Buscando\nun oponente',
  PlaySingle: 'Juego individual',
  PlaySingleDescription: 'Modo de olas infinitas',
  PlayOnline: 'Juego en línea',
  PlayOnlineDescription: 'Juega con un oponente aleatorio',
  PlayOnlinePrivate: 'Juego privado',
  PlayOnlinePrivateDescription: 'Juega con tu amigo',
  YourId: 'Tu ID',
  FriendId: 'ID de amigo',

  OrHold: 'O mantener',

  Yes: 'Sí',
  No: 'No',

  On: 'Encendido',
  Off: 'Apagado',

  Low: 'Bajo',
  Medium: 'Medio',
  High: 'Alto',

  To: 'a',

  [KeyAction.Press]: 'Presionar',
  [KeyAction.Hold]: 'Mantener',
  [`Swipe${SwipeDirection.Up}`]: 'Deslizar hacia arriba',
  [`Swipe${SwipeDirection.Left}`]: 'Deslizar hacia la izquierda',
  [`Hint${TutorialStep.Attack}`]: 'atacar enemigos',
  [`Hint${TutorialStep.UpgradeGenerator}`]: 'Toca el generador\npara mejorarlo',
  [`Hint${TutorialStep.UpgradeTowerFire}`]: 'Toca la torre\npara mejorarla',
  [`Hint${TutorialStep.UpgradePlayer}`]: 'mejorar personaje',
  [`Hint${TutorialStep.BuildGenerator}`]: 'construir generador',
  [`Hint${TutorialStep.BuildSecondGenerator}`]: 'construir segundo generador',
  [`Hint${TutorialStep.BuildAmmunition}`]: 'construir munición',
  [`Hint${TutorialStep.BuildSecondAmmunition}`]: 'construir segunda munición',
  [`Hint${TutorialStep.BuildRadar}`]: 'construir radar',
  [`Hint${TutorialStep.BuildTowerFire}`]: 'construir torre de fuego',
  [`Hint${TutorialStep.BuildTowerFrost}`]: 'construir torre de hielo',

  [`Parameter${ParameterType.Ammo}`]: 'Munición',
  [`Parameter${ParameterType.Resources}`]: 'Recursos',
  [`Parameter${ParameterType.Mobs}`]: 'Enemigos',
  [`Parameter${ParameterType.Health}`]: 'Salud',

  YourGoal: 'Tu objetivo',
  GoalDescription: 'Sobrevive tantas oleadas como sea posible,\ndefendiendo la base de diversos enemigos',
  Start: 'Iniciar',

  NewBuildingsAvailable: 'Nuevos edificios\ndisponibles',

  NotEnoughAmmo: 'Munición insuficiente',
  NeedRepair: 'Reparación necesaria',
  Upgraded: 'Mejorado',
  LevelUp: 'Subir de nivel',

  Wave: 'Oleada',
  PrepareForAttack: 'Prepárate para el ataque',
  BossWaveStarted: 'Jefe',
  WaveStarted: 'Oleada %1 iniciada',
  WaveCompleted: 'Oleada %1 completada',
  UntilWaveStart: 'Tiempo hasta\nel inicio de la oleada',
  EnemiesLeft: 'Enemigos\nrestantes',

  Experience: 'Experiencia',
  Upgrades: 'Mejoras',
  MaxLevel: 'Nivel máximo',

  SkillDuration: 'Duración %1 s',
  SkillRecovery: 'Recuperación %1 s',
  PassiveSkill: 'Pasiva',

  Empty: 'Vacío',
  Extra: 'Extra',

  Get: 'Obtener',
  Buy: 'Comprar',
  Select: 'Seleccionar',
  Selected: 'Seleccionado',

  Free: 'Gratis',
  YouHave: 'Tienes',
  Crystals: 'Cristales',
  Slot: 'Ranura',
  SelectSlotForSkill: 'Selecciona una ranura para la habilidad',

  [`Category${InventoryItemType.Droid}`]: 'Dron',
  [`Category${InventoryItemType.Skill}`]: 'Habilidades',

  [`${DroidVariant.Combat}Name`]: 'Combate',
  [`${DroidVariant.Combat}Description`]: 'Ataca a los enemigos dentro de un cierto radio',
  [`${DroidVariant.Medic}Name`]: 'Médico',
  [`${DroidVariant.Medic}Description`]: 'Cura al personaje en intervalos regulares',
  [`${DroidVariant.Mechanic}Name`]: 'Mecánico',
  [`${DroidVariant.Mechanic}Description`]: 'Repara edificios dañados dentro de un cierto radio',

  [`${SkillVariant.DiscountRepair}Name`]: 'Descuento en reparaciones',
  [`${SkillVariant.DiscountRepair}Description`]: 'Reduce el costo de reparación de edificios en un 20%',
  [`${SkillVariant.DistantVision}Name`]: 'Visión lejana',
  [`${SkillVariant.DistantVision}Description`]: 'Aumenta la distancia visible en un 20%',
  [`${SkillVariant.Vampire}Name`]: 'Vampirismo',
  [`${SkillVariant.Vampire}Description`]: 'Restaura la salud en un 5% del daño infligido',
  [`${SkillVariant.BoostSpeed}Name`]: 'Aumento de velocidad',
  [`${SkillVariant.BoostSpeed}Description`]: 'Aumenta la velocidad en un 50%',
  [`${SkillVariant.InspirationDroid}Name`]: 'Dron de inspiración',
  [`${SkillVariant.InspirationDroid}Description`]: 'Aumenta las características del dron en un 20%',
  [`${SkillVariant.InspirationMobs}Name`]: 'Inspiración de enemigos',
  [`${SkillVariant.InspirationMobs}Description`]: 'Aumenta el daño y la velocidad de los enemigos en un 10%',
  [`${SkillVariant.InspirationTowers}Name`]: 'Inspiración de torres',
  [`${SkillVariant.InspirationTowers}Description`]: 'Aumenta el daño y la velocidad de ataque de las torres en un 20%',
  [`${SkillVariant.Sacrifice}Name`]: 'Sacrificio',
  [`${SkillVariant.Sacrifice}Description`]: 'Mata a tus enemigos y aumenta tu salud',
  [`${SkillVariant.DiscountWalls}Name`]: 'Descuento en muros',
  [`${SkillVariant.DiscountWalls}Description`]: 'Reduce el costo de los muros en un 50%',
  [`${SkillVariant.DamageReflection}Name`]: 'Reflejo de daño',
  [`${SkillVariant.DamageReflection}Description`]: 'Refleja el 10% del daño recibido',
  [`${SkillVariant.BoostGenerators}Name`]: 'Impulso de generadores',
  [`${SkillVariant.BoostGenerators}Description`]: 'Aumenta la velocidad de generación de recursos en un 10%',
  [`${SkillVariant.CallingMobs}Name`]: 'Invocar enemigos',
  [`${SkillVariant.CallingMobs}Description`]: 'Genera enemigos cerca del personaje',
  [`${SkillVariant.AttackRecovery}Name`]: 'Ataques rápidos',
  [`${SkillVariant.AttackRecovery}Description`]: 'Aumenta la velocidad de recuperación de ataque en un 15%',
  [`${SkillVariant.HighDamage}Name`]: 'Alto daño',
  [`${SkillVariant.HighDamage}Description`]: 'Aumenta el daño del personaje en un 15%',
  [`${SkillVariant.FrostWave}Name`]: 'Ola de escarcha',
  [`${SkillVariant.FrostWave}Description`]: 'Congela a los enemigos dentro de un cierto radio',

  [BuildingCategory.Defense]: 'Defensa',
  [BuildingCategory.Spawners]: 'Generadores',
  [BuildingCategory.Resources]: 'Recursos',
  [BuildingCategory.Towers]: 'Torres',

  [`${BuildingVariant.Base}Name`]: 'Estación base',
  [`${BuildingVariant.Base}Description`]: '',
  [`${BuildingVariant.Wall}Name`]: 'Muro',
  [`${BuildingVariant.Wall}Description`]: 'Brinda protección a los edificios importantes',
  [`${BuildingVariant.Ammunition}Name`]: 'Depósito de munición',
  [`${BuildingVariant.Ammunition}Description`]: 'Produce munición para las torres',
  [`${BuildingVariant.Radar}Name`]: 'Radar',
  [`${BuildingVariant.Radar}Description`]: 'Detecta enemigos ocultos',
  [`${BuildingVariant.Trap}Name`]: 'Trampa',
  [`${BuildingVariant.Trap}Description`]: 'Atrae enemigos y refleja parte del daño',
  [`${BuildingVariant.Generator}Name`]: 'Generador',
  [`${BuildingVariant.Generator}Description`]: 'Crea recursos para la construcción',
  [`${BuildingVariant.SpawnerSmall}Name`]: 'Generador pequeño',
  [`${BuildingVariant.SpawnerSmall}Description`]: 'Genera enemigos de nivel bajo',
  [`${BuildingVariant.SpawnerMedium}Name`]: 'Generador mediano',
  [`${BuildingVariant.SpawnerMedium}Description`]: 'Genera enemigos de nivel medio',
  [`${BuildingVariant.SpawnerLarge}Name`]: 'Generador grande',
  [`${BuildingVariant.SpawnerLarge}Description`]: 'Genera enemigos de nivel alto',
  [`${BuildingVariant.TowerFire}Name`]: 'Torre de fuego',
  [`${BuildingVariant.TowerFire}Description`]: 'Ataca a los enemigos con bolas de fuego',
  [`${BuildingVariant.TowerElectro}Name`]: 'Torre eléctrica',
  [`${BuildingVariant.TowerElectro}Description`]: 'Daña a los enemigos dentro del alcance',
  [`${BuildingVariant.TowerFrost}Name`]: 'Torre de escarcha',
  [`${BuildingVariant.TowerFrost}Description`]: 'Ralentiza a los enemigos',
  [`${BuildingVariant.TowerLaser}Name`]: 'Torre láser',
  [`${BuildingVariant.TowerLaser}Description`]: 'Ataca instantáneamente a los enemigos con un láser',

  [UpgradeCategory.Main]: 'Principal',
  [UpgradeCategory.Attack]: 'Ataque',
  [UpgradeCategory.Build]: 'Construcción',

  [UpgradeVariant.MainMaxHealth]: 'Salud máxima',
  [UpgradeVariant.MainSpeed]: 'Velocidad de movimiento',
  [UpgradeVariant.MainDroid]: 'Eficiencia del dron',
  [UpgradeVariant.AttackRecovery]: 'Recuperación de ataque',
  [UpgradeVariant.AttackRadius]: 'Radio de ataque',
  [UpgradeVariant.AttackDamage]: 'Daño',
  [UpgradeVariant.BuildSpeed]: 'Velocidad de construcción',
  [UpgradeVariant.BuildRadius]: 'Radio de construcción',

  [SettingsType.FpsLimit]: 'Límite de FPS',
  [SettingsType.Resolution]: 'Calidad gráfica',
  [SettingsType.VisualEffects]: 'Efectos visuales',
  [SettingsType.AudioEffects]: 'Efectos de sonido',
  [SettingsType.Fullscreen]: 'Pantalla completa',
  [SettingsType.Language]: 'Idioma',
};

import React from 'react';

import { Interface } from '../ui';

import type { SystemMessageConfig } from './types';
import { SystemMessageScreen } from './ui/components/screen';

export class SystemMessage {
  public static render(
    id: string,
    { message, ...props }: SystemMessageConfig,
  ) {
    return Interface.inject(
      id,
      <SystemMessageScreen {...props}>
        {(Array.isArray(message) ? message : [message]).map((line, i) => (
          <p key={i}>{line}</p>
        ))}
      </SystemMessageScreen>,
    );
  }
}

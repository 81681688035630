import cn from 'classnames';
import React, { useRef } from 'react';

import { Button } from '~core/client/ui/components/button';
import { Section } from '~core/client/ui/components/section';

import type { SystemMessageType } from '../../../types';

import styles from './styles.module.scss';

type Props = {
  sign: string;
  type: SystemMessageType;
  title: string;
  skippable?: boolean;
  children: React.ReactNode;
};

export const SystemMessageScreen: React.FC<Props> = ({
  sign,
  type,
  title,
  skippable,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    ref.current?.parentElement?.remove();
  };

  return (
    <div ref={ref} className={cn(styles.overlay, styles[type.toLowerCase()])}>
      <div className={styles.container}>
        <Section direction='vertical' align='center' gap={64}>
          <Section direction='vertical' align='center' gap={8}>
            <h1 className={styles.sign}>{sign}</h1>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.message}>{children}</div>
          </Section>
          {skippable && (
            <Button size='s' view='dark' onClick={handleClose}>Continue</Button>
          )}
        </Section>
      </div>
    </div>
  );
};

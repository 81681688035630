import type React from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { Language } from '~core/client/language';

import { LanguageState } from '../../state';

export const LanguageSync: React.FC = () => {
  const [, setLanguage] = useRecoilState(LanguageState);

  // Updating the current state value of the language wen mountain the interface.
  // Using instead of the default value for state,
  // because it is not possible to change the default value when changing the language.
  useEffect(() => {
    setLanguage(Language.getType());
  }, []);

  return null;
};


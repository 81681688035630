import type { DICTIONARIES } from './const';

export enum LanguageType {
  EN = 'EN',
  FR = 'FR',
  ES = 'ES',
  DE = 'DE',
  RU = 'RU',
}

export type DictionaryPhrase = keyof typeof DICTIONARIES[LanguageType.EN];

/* eslint-disable import/no-restricted-paths */

import { SettingsType } from '~core/client/settings/types';

import { ParameterType } from '~feature/client/battle/entity/unit/player/ui/components/info-panel/parameter/types';
import { KeyAction } from '~feature/client/battle/tutorial/ui/components/hints/content/key/types';
import { SwipeDirection } from '~feature/client/battle/tutorial/ui/components/hints/content/swipe/types';
import { PageType } from '~feature/client/relay/ui/components/pages/types';
import { BuildingCategory, BuildingVariant } from '~feature/shared/battle/entity/building/types';
import { DroidVariant } from '~feature/shared/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~feature/shared/battle/entity/unit/player/skill/types';
import { UpgradeCategory, UpgradeVariant } from '~feature/shared/battle/entity/unit/player/upgrades/types';
import { TutorialStep } from '~feature/shared/battle/tutorial/types';
import { BattleDifficult } from '~feature/shared/battle/types';
import { InventoryItemType } from '~feature/shared/mixed/inventory/item/types';
import { UserError } from '~feature/shared/mixed/user/types';

const PHRASES = {
  [PageType.Play]: 'Spielen',
  [PageType.Inventory]: 'Inventar',
  [PageType.Leaderboard]: 'Rangliste',
  [PageType.Profile]: 'Profil',

  LeaveAccount: 'Konto verlassen',
  ToLogIn: 'Anmelden',
  ToSignUp: 'Registrieren',
  LogIn: 'Anmelden',
  SignUp: 'Registrieren',
  Username: 'Benutzername',
  Password: 'Passwort',
  AcceptTerms: 'Ich akzeptiere die Bedingungen von',
  PrivacyPolicy: 'der Datenschutzrichtlinie',

  [UserError.ExistUsername]: 'Der angegebene Benutzername ist bereits vergeben',
  [UserError.InvalidToken]: 'Ungültiges Autorisierungstoken',
  [UserError.InvalidCredentials]: 'Falscher Benutzername oder Passwort',
  [UserError.InvalidUsername]: 'Ungültiger Benutzername',
  [UserError.InvalidPassword]: 'Ungültiges Passwort',

  MainMenu: 'Hauptmenü',
  Continue: 'Fortsetzen',
  Restart: 'Neustart',
  Settings: 'Einstellungen',
  RestartConfirm: 'Sind Sie sicher, dass Sie von vorne beginnen möchten?',
  LeaveConfirm: 'Sind Sie sicher, dass Sie diesen Kampf verlassen möchten?',
  Back: 'Zurück',

  Place: 'Platz',
  MaxWave: 'Maximale Welle',
  TotalWaves: 'Gesamtwellen',

  AuthTo: 'Anmelden bei',
  SaveReward: 'Belohnung speichern',
  LoadSave: 'Spielstand laden',
  PlayAgain: 'Nochmal spielen',
  WaitingOpponent: 'Warten auf Gegner',
  OpponentDisconnected: 'Der Gegner hat den Kampf verlassen',

  YouWin: 'Du hast gewonnen',
  YouLose: 'Du hast verloren',
  YouDied: 'Du bist gestorben',
  BaseDestroyed: 'Basis zerstört',

  [`Difficult${BattleDifficult.Tutorial}`]: 'Tutorial',
  [`Difficult${BattleDifficult.Tutorial}Description`]: 'Für das erste Spiel',
  [`Difficult${BattleDifficult.Normal}`]: 'Normal',
  [`Difficult${BattleDifficult.Normal}Description`]: 'Für Anfänger',
  [`Difficult${BattleDifficult.Hard}`]: 'Schwer',
  [`Difficult${BattleDifficult.Hard}Description`]: 'Für erfahrene Spieler',

  PlayerOnline: 'Spieler online',
  PlayersOnline: 'Spieler online',

  YouRanked: 'Du bist platziert auf',
  PlaceOf: 'Platz von',

  SearchingOpponent: 'Suche nach\neinem Gegner',
  PlaySingle: 'Einzelspiel',
  PlaySingleDescription: 'Spiele den endlosen Wellenmodus',
  PlayOnline: 'Online spielen',
  PlayOnlineDescription: 'Spiele gegen einen zufälligen Gegner',
  PlayOnlinePrivate: 'Privatspiel',
  PlayOnlinePrivateDescription: 'Spiele mit deinem Freund',
  YourId: 'Deine ID',
  FriendId: 'Freund-ID',

  OrHold: 'Oder halten',

  Yes: 'Ja',
  No: 'Nein',

  On: 'Ein',
  Off: 'Aus',

  Low: 'Niedrig',
  Medium: 'Mittel',
  High: 'Hoch',

  To: 'zu',

  [KeyAction.Press]: 'Drücken',
  [KeyAction.Hold]: 'Halten',
  [`Swipe${SwipeDirection.Up}`]: 'Nach oben wischen',
  [`Swipe${SwipeDirection.Left}`]: 'Nach links wischen',
  [`Hint${TutorialStep.Attack}`]: 'Feinde angreifen',
  [`Hint${TutorialStep.UpgradeGenerator}`]: 'Tippe auf den Generator,\num ihn zu verbessern',
  [`Hint${TutorialStep.UpgradeTowerFire}`]: 'Tippe auf den Turm,\num ihn zu verbessern',
  [`Hint${TutorialStep.UpgradePlayer}`]: 'Charakter verbessern',
  [`Hint${TutorialStep.BuildGenerator}`]: 'Generator bauen',
  [`Hint${TutorialStep.BuildSecondGenerator}`]: 'Zweiten Generator bauen',
  [`Hint${TutorialStep.BuildAmmunition}`]: 'Munition bauen',
  [`Hint${TutorialStep.BuildSecondAmmunition}`]: 'Zweite Munition bauen',
  [`Hint${TutorialStep.BuildRadar}`]: 'Radar bauen',
  [`Hint${TutorialStep.BuildTowerFire}`]: 'Feuerturm bauen',
  [`Hint${TutorialStep.BuildTowerFrost}`]: 'Frostturm bauen',

  [`Parameter${ParameterType.Ammo}`]: 'Munition',
  [`Parameter${ParameterType.Resources}`]: 'Ressourcen',
  [`Parameter${ParameterType.Mobs}`]: 'Gegner',
  [`Parameter${ParameterType.Health}`]: 'Gesundheit',

  YourGoal: 'Dein Ziel',
  GoalDescription: 'Überlebe so viele Wellen wie möglich\nund verteidige die Basis gegen verschiedene Feinde',
  Start: 'Starten',

  NewBuildingsAvailable: 'Neue Gebäude\nverfügbar',

  NotEnoughAmmo: 'Nicht genug Munition',
  NeedRepair: 'Reparatur erforderlich',
  Upgraded: 'Verbessert',
  LevelUp: 'Levelaufstieg',

  Wave: 'Welle',
  PrepareForAttack: 'Bereite dich auf den Angriff vor',
  BossWaveStarted: 'Boss',
  WaveStarted: 'Welle %1 gestartet',
  WaveCompleted: 'Welle %1 abgeschlossen',
  UntilWaveStart: 'Bis zum\nStart der Welle',
  EnemiesLeft: 'Verbleibende\nFeinde',

  Experience: 'Erfahrung',
  Upgrades: 'Upgrades',
  MaxLevel: 'Maximales Level',

  SkillDuration: 'Dauer %1 s',
  SkillRecovery: 'Erholung %1 s',
  PassiveSkill: 'Passiv',

  Empty: 'Leer',
  Extra: 'Extra',

  Get: 'Erhalten',
  Buy: 'Kaufen',
  Select: 'Auswählen',
  Selected: 'Ausgewählt',

  Free: 'Gratis',
  YouHave: 'Du hast',
  Crystals: 'Kristalle',
  Slot: 'Slot',
  SelectSlotForSkill: 'Wähle einen Slot für die Fähigkeit',

  [`Category${InventoryItemType.Droid}`]: 'Droid',
  [`Category${InventoryItemType.Skill}`]: 'Fähigkeiten',

  [`${DroidVariant.Combat}Name`]: 'Kampf',
  [`${DroidVariant.Combat}Description`]: 'Greift feindliche Gegner in einem bestimmten Radius an',
  [`${DroidVariant.Medic}Name`]: 'Sanitäter',
  [`${DroidVariant.Medic}Description`]: 'Heilt den Charakter in bestimmten Abständen',
  [`${DroidVariant.Mechanic}Name`]: 'Mechaniker',
  [`${DroidVariant.Mechanic}Description`]: 'Repariert beschädigte Gebäude in einem bestimmten Radius',

  [`${SkillVariant.DiscountRepair}Name`]: 'Reparatur-Rabatt',
  [`${SkillVariant.DiscountRepair}Description`]: 'Reduziert die Reparaturkosten von Gebäuden um 20%',
  [`${SkillVariant.DistantVision}Name`]: 'Fernsicht',
  [`${SkillVariant.DistantVision}Description`]: 'Erhöht die Sichtweite um 20%',
  [`${SkillVariant.Vampire}Name`]: 'Vampirismus',
  [`${SkillVariant.Vampire}Description`]: 'Stellt 5% des verursachten Schadens als Gesundheit wieder her',
  [`${SkillVariant.BoostSpeed}Name`]: 'Geschwindigkeitsboost',
  [`${SkillVariant.BoostSpeed}Description`]: 'Erhöht die Geschwindigkeit um 50%',
  [`${SkillVariant.InspirationDroid}Name`]: 'Inspirations-Droid',
  [`${SkillVariant.InspirationDroid}Description`]: 'Erhöht die Droiden-Eigenschaften um 20%',
  [`${SkillVariant.InspirationMobs}Name`]: 'Inspirations-Mobs',
  [`${SkillVariant.InspirationMobs}Description`]: 'Erhöht den Schaden und die Geschwindigkeit der Mobs um 10%',
  [`${SkillVariant.InspirationTowers}Name`]: 'Inspirations-Türme',
  [`${SkillVariant.InspirationTowers}Description`]: 'Erhöht den Schaden und die Angriffsgeschwindigkeit der Türme um 20%',
  [`${SkillVariant.Sacrifice}Name`]: 'Opfergabe',
  [`${SkillVariant.Sacrifice}Description`]: 'Tötet deine Mobs und erhöht deine Gesundheit',
  [`${SkillVariant.DiscountWalls}Name`]: 'Mauer-Rabatt',
  [`${SkillVariant.DiscountWalls}Description`]: 'Reduziert die Mauerkosten um 50%',
  [`${SkillVariant.DamageReflection}Name`]: 'Schadensreflexion',
  [`${SkillVariant.DamageReflection}Description`]: 'Reflektiert 10% des erlittenen Schadens',
  [`${SkillVariant.BoostGenerators}Name`]: 'Generator-Boost',
  [`${SkillVariant.BoostGenerators}Description`]: 'Erhöht die Ressourcenerzeugungsgeschwindigkeit um 10%',
  [`${SkillVariant.CallingMobs}Name`]: 'Mobs rufen',
  [`${SkillVariant.CallingMobs}Description`]: 'Beschwört Mobs in der Nähe des Charakters',
  [`${SkillVariant.AttackRecovery}Name`]: 'Schnelle Angriffe',
  [`${SkillVariant.AttackRecovery}Description`]: 'Erhöht die Angriffserholungsgeschwindigkeit um 15%',
  [`${SkillVariant.HighDamage}Name`]: 'Hoher Schaden',
  [`${SkillVariant.HighDamage}Description`]: 'Erhöht den Charakter-Schaden um 15%',
  [`${SkillVariant.FrostWave}Name`]: 'Frostwelle',
  [`${SkillVariant.FrostWave}Description`]: 'Friert feindliche Mobs in einem bestimmten Radius ein',

  [BuildingCategory.Defense]: 'Verteidigung',
  [BuildingCategory.Spawners]: 'Spawner',
  [BuildingCategory.Resources]: 'Ressourcen',
  [BuildingCategory.Towers]: 'Türme',

  [`${BuildingVariant.Base}Name`]: 'Basisstation',
  [`${BuildingVariant.Base}Description`]: '',
  [`${BuildingVariant.Wall}Name`]: 'Mauer',
  [`${BuildingVariant.Wall}Description`]: 'Bietet Schutz für wichtige Gebäude',
  [`${BuildingVariant.Ammunition}Name`]: 'Munitionslager',
  [`${BuildingVariant.Ammunition}Description`]: 'Produziert Munition für Türme',
  [`${BuildingVariant.Radar}Name`]: 'Radar',
  [`${BuildingVariant.Radar}Description`]: 'Erkennt versteckte Feinde',
  [`${BuildingVariant.Trap}Name`]: 'Falle',
  [`${BuildingVariant.Trap}Description`]: 'Lockt Feinde an und reflektiert einen Teil des Schadens',
  [`${BuildingVariant.Generator}Name`]: 'Generator',
  [`${BuildingVariant.Generator}Description`]: 'Erzeugt Ressourcen für den Bau',
  [`${BuildingVariant.SpawnerSmall}Name`]: 'Kleiner Spawner',
  [`${BuildingVariant.SpawnerSmall}Description`]: 'Spawnt niedrigstufige Mobs',
  [`${BuildingVariant.SpawnerMedium}Name`]: 'Mittlerer Spawner',
  [`${BuildingVariant.SpawnerMedium}Description`]: 'Spawnt mittelstufige Mobs',
  [`${BuildingVariant.SpawnerLarge}Name`]: 'Großer Spawner',
  [`${BuildingVariant.SpawnerLarge}Description`]: 'Spawnt hochstufige Mobs',
  [`${BuildingVariant.TowerFire}Name`]: 'Feuerturm',
  [`${BuildingVariant.TowerFire}Description`]: 'Greift Feinde mit Feuerbällen an',
  [`${BuildingVariant.TowerElectro}Name`]: 'Elektroturm',
  [`${BuildingVariant.TowerElectro}Description`]: 'Verursacht Schaden bei Feinden in Reichweite',
  [`${BuildingVariant.TowerFrost}Name`]: 'Frostturm',
  [`${BuildingVariant.TowerFrost}Description`]: 'Verlangsamt Feinde',
  [`${BuildingVariant.TowerLaser}Name`]: 'Laserturm',
  [`${BuildingVariant.TowerLaser}Description`]: 'Greift Feinde sofort mit einem Laser an',

  [UpgradeCategory.Main]: 'Haupt',
  [UpgradeCategory.Attack]: 'Angriff',
  [UpgradeCategory.Build]: 'Bau',

  [UpgradeVariant.MainMaxHealth]: 'Maximale Gesundheit',
  [UpgradeVariant.MainSpeed]: 'Geschwindigkeit',
  [UpgradeVariant.MainDroid]: 'Droiden-Effizienz',
  [UpgradeVariant.AttackRecovery]: 'Angriffserholung',
  [UpgradeVariant.AttackRadius]: 'Angriffsreichweite',
  [UpgradeVariant.AttackDamage]: 'Schaden',
  [UpgradeVariant.BuildSpeed]: 'Baugeschwindigkeit',
  [UpgradeVariant.BuildRadius]: 'Bauradius',

  [SettingsType.FpsLimit]: 'FPS-Limit',
  [SettingsType.Resolution]: 'Grafikqualität',
  [SettingsType.VisualEffects]: 'Visuelle Effekte',
  [SettingsType.AudioEffects]: 'Audioeffekte',
  [SettingsType.Fullscreen]: 'Vollbild',
  [SettingsType.Language]: 'Sprache',
};

export default PHRASES;

import type { Schema } from '@colyseus/schema';
import type { Room as OriginRoom } from 'colyseus.js';
import type React from 'react';

import { Client } from '~core/client/client';
import { Request } from '~core/client/router/request';
import type { Scene } from '~core/client/scene';
import { SystemMessage } from '~core/client/system-message';
import { SystemMessageType } from '~core/client/system-message/types';
import { Interface } from '~core/client/ui';
import type { WebSocketErrorCode } from '~core/shared/router/websocket/types';
import { WebSocketCloseCode } from '~core/shared/router/websocket/types';
import { SERVER_CHECK_CONNECTION_ROUTE_PATH } from '~core/shared/server/const';
import { ServerShutdownCode } from '~core/shared/server/types';
import { Utils } from '~core/shared/utils';

import { ROOM_RECONNECTION_INTERVAL } from './const';
import type { RequestPromise } from './types';

export class Room<T extends Schema = Schema> {
  public static allowReconnection: boolean = false;

  public readonly origin: OriginRoom<T>;

  public get state() { return this.origin.state; }

  public get id() { return this.origin.roomId; }

  public get sessionId() { return this.origin.sessionId; }

  public scene: Scene;

  private requests: Map<string, RequestPromise> = new Map();

  private shutdown: boolean = false;

  private ui: Nullable<React.FC> = null;

  constructor(room: OriginRoom<T>) {
    this.origin = room;

    this.origin.onMessage('*', this.handleMessage.bind(this));
    this.origin.onError(this.handleError.bind(this));
    this.origin.onLeave(this.handleLeave.bind(this));
  }

  protected destroy(): void {
    Interface.unmount();
  }

  private handleMessage(type: string, { requestId, error, response }: any = {}) {
    const promise = requestId && this.requests.get(requestId);
    if (!promise) {
      return;
    }

    this.requests.delete(requestId);

    if (error) {
      promise.reject(new Error(error));
    } else {
      promise.resolve(response);
    }
  }

  private handleError(code: WebSocketErrorCode, message?: string) {
    this.showErrorScreen(message);
  }

  private async handleLeave(code: WebSocketCloseCode) {
    this.destroy();

    const shutdownCodes = Object.values(ServerShutdownCode) as number[];
    if (shutdownCodes.includes(code)) {
      this.shutdown = true;
    }

    if (code === WebSocketCloseCode.Error) {
      return;
    }

    const consented = code === WebSocketCloseCode.Normal;
    const connected = await this.checkConnection(!consented);
    if (!connected) {
      Client.setLastRoomId(this.origin.roomId);
      this.tryReconnect();
    }
  }

  private showErrorScreen(message?: string | string[]) {
    SystemMessage.render('system-error', {
      type: SystemMessageType.Error,
      sign: '☠️',
      title: 'Server Error',
      message,
    });
  }

  protected setUI(component: React.FC) {
    this.ui = component;
    Interface.mount(component, this);
  }

  public refreshUI() {
    if (!this.ui) {
      return;
    }

    Interface.unmount();
    Interface.mount(this.ui, this);
  }

  private async checkConnection(join: boolean = true) {
    try {
      await Request.get(SERVER_CHECK_CONNECTION_ROUTE_PATH);
      if (this.shutdown) {
        location.reload();
      } else if (join) {
        Client.connect();
        await Client.findAndJoinRoom();
      }
      return true;
    } catch {
      return false;
    }
  }

  private async tryReconnect() {
    const error = this.shutdown
      ? SystemMessage.render('restarting', {
        type: SystemMessageType.Info,
        sign: '🔄',
        title: 'Restarting',
        message: [
          'Game server is restarting',
          'Please, wait...',
        ],
      })
      : SystemMessage.render('connection-lost', {
        type: SystemMessageType.Error,
        sign: '📡',
        title: 'Connection Lost',
        message: 'Trying to reconnect...',
      });

    const interval = setInterval(async () => {
      const connected = await this.checkConnection();
      if (connected) {
        clearInterval(interval);
        error.remove();
      }
    }, ROOM_RECONNECTION_INTERVAL);
  }

  public async sendRequest<T = any>(type: string, payload?: any): Promise<T> {
    if (!this.origin.connection.isOpen) {
      // if (__MODE === 'development') {
      //   Logger.warn(`Unable to send request '${type}' from disconnected room`);
      // }
      // @ts-ignore
      return;
    }

    const requestId = Utils.uuid();
    this.origin.send(type, { payload, requestId });

    return new Promise((resolve, reject) => {
      this.requests.set(requestId, { resolve, reject });
    });
  }

  public onStateChange(callback: VoidFunction) {
    const listener = this.origin.onStateChange(callback);

    return {
      unsubscribe: () => {
        listener.clear();
      },
    };
  }
}
